import React, { Component } from "react";
import { fire } from "../fire";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '70%',
  height: '70%'
};

export class DunduspointChatham extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      latitude: 11.6744,
      longitude: 92.7330
    };
  }

  componentDidMount() {
    this.updateUserLocation();
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchId);
  }

  updateUserLocation = () => {
    if (navigator.geolocation) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          this.setState({ 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude 
          }, () => {
            // Callback function to ensure component is mounted before updating state
            // Push the location data to Firebase
            fire.database().ref('DunduspointChatham/').push({
              latitude: this.state.latitude,
              longitude: this.state.longitude
            });
            this.centerMapToCurrentLocation();
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  centerMapToCurrentLocation = () => {
    const { latitude, longitude } = this.state;
    const map = this.mapRef.current.map;
    const center = new this.props.google.maps.LatLng(latitude, longitude);
    map.panTo(center);
  }

  render() {
    const { latitude, longitude } = this.state;
    return (
      <div className="flex-col mx-auto text-center">
        <h4>Dunduspoint to Chatham</h4>
        <Map
          ref={this.mapRef}
          google={this.props.google}
          zoom={14}
          style={mapStyles}
          initialCenter={{
            lat: latitude,
            lng: longitude
          }}
        >
          <Marker
            onClick={this.onMarkerClick}
            name={'This is test name'}
            position={{
              lat: latitude,
              lng: longitude
            }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDnSNNGQQ8AhLEmcsXJbmz1_MVrbOz55rM'
})(DunduspointChatham);
