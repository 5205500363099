import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '70%',
  height: '70%'
};

export class BambooflatChatham extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      latitude: null,
      longitude: null
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude }, () => {
          this.centerMapToCurrentLocation();
        });
      });
    }
  }
  

  centerMapToCurrentLocation = () => {
    const { latitude, longitude } = this.state;
    const map = this.mapRef.current.map;
    const center = new this.props.google.maps.LatLng(latitude, longitude);
    map.panTo(center);
  }

  render() {
    const { latitude, longitude } = this.state;
    return (
      <div className="flex-col mx-auto text-center">
        <h4>Select A location To see the Map</h4>
        <Map
          ref={this.mapRef}
          google={this.props.google}
          zoom={14}
          style={mapStyles}
          initialCenter={{
            lat: latitude,
            lng: longitude
          }}
        >
          <Marker
            name={'This is test name'}
            position={{
              lat: latitude,
              lng: longitude
            }}
          />
        </Map>
      </div>
    );
  }
}



export default GoogleApiWrapper({
  apiKey: 'AIzaSyDnSNNGQQ8AhLEmcsXJbmz1_MVrbOz55rM'
})(BambooflatChatham);
