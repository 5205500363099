import React, { Component } from "react";
import BambooflatChatham from "./Bambooflat-Chatham/Bambooflat-Chatham";
import ChathamBambooflat from "./Chatham-Bambooflat/Chatham-Bambooflat";
import MarineBambooflat from "./Marine-Bambooflat/Marine-Bambooflat";
import BambooflatMarine from "./Bambooflat-Marine/Bambooflat-Marine";
import HaddoHavelock from "./Haddo-Havelock/Haddo-Havelock";
import HavelockHaddo from "./Havelock-Haddo/Havelock-Haddo";
import DefaultLocation from "./DefaultLocation/DefaultLocation";
import ChathamDunduspoint from "./Chatham-Dunduspoint/Chatham-Dunduspoint";
import DunduspointChatham from "./Dunduspoint-Chatham/Dunduspoint-Chatham";
import MarinePanighat from "./Marine-Panighat/Marine-Panighat";
import PanighatMarine from "./Panighat-Marine/Panighat-Marine";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "defaultLocation"
    };

  }

  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  }



  // componentDidMount() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.watchPosition(function(position) {
  //       console.log("Latitude is :", position.coords.latitude);
  //       console.log("Longitude is :", position.coords.longitude);
  //     });
  //   }
  // }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="flex-col mx-auto">
        <h4 className="mb-10">Using geolocation JavaScript API in React</h4>
        <select value={selectedOption} onChange={this.handleOptionChange}>
          <option value="defaultLocation">Select your location</option>
          <option value="BambooflatChatham">BambooflatChatham</option>
          <option value="ChathamBambooflat">ChathamBambooflat</option>
          <option value="MarineBambooflat">MarineBambooflat</option>
          <option value="BambooflatMarine">BambooflatMarine</option>
          <option value="HaddoHavelock">HaddoHavelock</option>
          <option value="HavelockHaddo">HavelockHaddo</option>
          <option value="ChathamDunduspoint">ChathamDunduspoint</option>
          <option value="DunduspointChatham">DunduspointChatham</option>
          <option value="MarinePanighat">MarinePanighat</option>
          <option value="PanighatMarine">PanighatMarine</option>
        </select>
        {selectedOption === "defaultLocation" && <DefaultLocation />}
        {selectedOption === "BambooflatChatham" && <BambooflatChatham />}
        {selectedOption === "ChathamBambooflat" && <ChathamBambooflat />}
        {selectedOption === "MarineBambooflat" && <MarineBambooflat />}
        {selectedOption === "BambooflatMarine" && <BambooflatMarine />}
        {selectedOption === "HaddoHavelock" && <HaddoHavelock />}
        {selectedOption === "HavelockHaddo" && <HavelockHaddo />}
        {selectedOption === "ChathamDunduspoint" && <ChathamDunduspoint />}
        {selectedOption === "DunduspointChatham" && <DunduspointChatham />}
        {selectedOption === "MarinePanighat" && <MarinePanighat />}
        {selectedOption === "PanighatMarine" && <PanighatMarine />}
      </div>
    );
  }
}

